import React, { useState } from 'react'

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
    <nav className="bg-navColor dark:bg-navColor fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-navColor shadow-xl z-100">
      <div className="max-w-screen-xl grid grid-cols-3 items-center mx-auto p-4">
        <a href="#" className="col-start-1 flex items-center space-x-3 rtl:space-x-reverse">
          <img src={process.env.PUBLIC_URL + '/kiatoweb-logo2.webp'} className=" h-[40px]" alt="Flowbite Logo"></img>
        </a>
        <div className="col-start-3 flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse justify-end">
        <button onClick={() => setIsOpen(!isOpen)} data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-navColor focus:outline-none focus:ring-2 focus:ring-navColor" aria-controls="navbar-sticky" aria-expanded="false">
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path className='h-[15px]' strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
        </div>
        <div className={`col-start-2 items-center justify-center ${isOpen ? 'flex' : 'hidden'} md:flex md:w-auto md:order-1`} id="navbar-sticky">
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-navColor rounded-lg bg-navColor md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 ">
          <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#skills" onClick={() => setIsOpen(false)} className="block py-2 text-2xl px-3 text-white rounded  md:hover:bg-transparent hover:text-red-500 md:p-0">Compétences</a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#portfolio" onClick={() => setIsOpen(false)} className="block py-2 text-2xl px-3 text-white rounded  md:hover:bg-transparent hover:text-red-500 md:p-0">Réalisations</a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#contact" onClick={() => setIsOpen(false)} className="block py-2 text-2xl px-3 text-white rounded  md:hover:bg-transparent hover:text-red-500 md:p-0">Contact</a>
            </li>    

          </ul>
        </div>
      </div>
    </nav>
    </>
  )
}