import './App.css';
import Contact from './component/Contact';
import Footer from './component/Footer';
import Navbar from './component/Navbar';
import Portfolio from './component/Portfolio';
import Skills from './component/Skills';

export default function App() {
  return (
    <>
      <Navbar/>
      <div className="relative-h-sreen">
        <img src={process.env.PUBLIC_URL + '/paper.webp'} className="hidden md:block absolute w-full h-full object-cover z-10" alt="Hero" />
        <img src={process.env.PUBLIC_URL + '/paper-small.webp'} className="top-[35px] md:hidden absolute w-full h-full object-fit z-10" alt="Hero" />
        <img src={process.env.PUBLIC_URL + '/logo.png'} className="animate-fadeIn absolute top-1/2 left-1/2 md:left-2/3 transform -translate-x-1/2 -translate-y-1/2 w-[300px] h-auto md:w-1/3 object-cover z-10 " alt="Hero" />
        <video src={process.env.PUBLIC_URL + '/japanese.mp4'} className="top-[75px] md:w-1/2 w-full h-screen object-cover z-0" autoPlay loop muted />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-5 z-1"></div>
      </div>
      <div className="absolute z-10 md:top-1/4 top-1/3 left-1/2 md:left-2/3 transform -translate-x-1/2 -translate-y-1/2 text-themeColor font-bold text-xl md:text-3xl opacity-90 typewriter">
        <h1>THOMAS KIATIKHEMA</h1>
      </div>
      <div className="absolute z-10 animate-fadeIn mt-6 md:top-3/4 top-2/3 left-1/2 md:left-2/3 transform -translate-x-1/2 -translate-y-1/2 text-themeColor text-center ">
        <h1 className='font-semibold text-md md:text-3xl'>Développeur Full Stack</h1>
        <div className="w-full border-b border-themeColor my-5"></div>
        <h2 className='text-sm md:text-xl'>Site vitrine, e-commerce, application</h2>
      </div>

      <Skills />
      <Portfolio />
      <Contact />
      <Footer />
    
    </>
  );
}