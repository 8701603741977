import React from 'react'
import real from './real.webp'
import { SiSimilarweb } from "react-icons/si";

export default function Portfolio() {

  const divStyle = {
    backgroundImage: `url(${real})`,
    backgroundAttachment: 'fixed', 
  };

  const overlayStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };

  return (
    <>
    <div style={divStyle} className="p-5 pt-5 bg-no-repeat bg-center bg-cover relative h-[250px] md:h-[400px] shadow-inner" id='portfolio'>
      <div style={overlayStyle}></div>
      <div className="absolute left-0 w-full h-full flex items-center justify-center text-center text-white flex-col">
        <div className='shadow-xl'>
          <h2 className='font-bold text-3xl md:text-5xl '>Exemples de réalisations</h2>
          <img src={process.env.PUBLIC_URL + '/brush2.png'} className=" mx-auto w-[200px] h-[10px] md:w-[350px] md:h-[15px] object-cover  mt-5 z-0" alt="Hero" />
          <h3 className='mt-4 text-xl md:text-3xl'>Développement, Déploiement, Maintenance</h3>
        </div>
      </div>
    </div>
    <div className='flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-[150px] mt-[50px] mx-3'>
      <img src={process.env.PUBLIC_URL + '/devices-parrot.webp'} alt="Description" className='w-full md:w-1/3 mb-10 md:mb-0  mx-3' />
      <div className='mx-auto md:mx-3  text-justify'>
      <h2 className='font-bold text-2xl mb-3 text-center flex flex-wrap items-center justify-center text-themeColor'>
        <SiSimilarweb className="mr-2 text-red-500" />
        Site de garage automobile 
        <a href="https://garage-parrot.vercel.app/" target="_blank" rel="noopener noreferrer" className='text-blue-600 ml-2'>Vincent Parrot</a>
      </h2>
        <div className='max-w-[550px] text-themeColor'>
          <p>Le site repose sur React, une bibliothèque performante permettant de créer des interfaces web riches et réactives.</p><br/>
          <p>Pour le back-end, j'ai opté pour Express.js, un cadre robuste pour construire une API RESTful sécurisée.</p><br/>
          <p>En combinant ces technologies, j'ai entièrement développé “from scratch” un système de gestion de contenu (CMS) intuitif, modulaire et évolutif.</p>
        </div>
      </div>
    </div>
    <div className=" border-b border-gray-400 w-2/3 mx-auto my-[50px]" ></div>
    <div className='flex flex-col md:flex-row-reverse items-center justify-center mt-[50px] mx-3'>
      <img src={process.env.PUBLIC_URL + '/devices-engramma.webp'} alt="Description" className='w-full md:w-1/3 mb-10 md:mb-0 mx-3 md:ml-0' />
      <div className='mx-auto md:mx-3 text-justify md:mr-[150px]'>
      <h2 className='font-bold text-2xl mb-3 text-center flex flex-wrap items-center justify-center text-themeColor'>
        <SiSimilarweb className="mr-2 text-red-500" />
        Site de groupe musical
        <a href="https://en-gramma.com" target="_blank" rel="noopener noreferrer" className='text-blue-600 ml-2'>En Gramma</a>
      </h2>
        <div className='max-w-[550px] text-themeColor'>
          <p>Pour le groupe EN GRAMMA, j'ai développé un site vitrine moderne, codé à la main avec React pour le front-end et Express pour la gestion back-end/API. </p><br/>
          <p>Suivant une maquette livrée, le projet a été géré en temps réel via Trello.</p><br/>
          <p>L’ntégration d'un CMS sur-mesure leur permettant de mettre à jour aisément les contenus a été l’un des point fort de ce projet.</p>
        </div>
      </div>
    </div>
  </>
  )
}