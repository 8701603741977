import React from 'react'
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa'
import { FaLocationDot } from "react-icons/fa6";


export default function Contact() {
  
  // const divStyle = {
  //   backgroundImage: `url(${real})`,
  //   backgroundAttachment: 'fixed', 
  // };

  // const overlayStyle = {
  //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
  //   position: 'absolute',
  //   top: 0,
  //   right: 0,
  //   bottom: 0,
  //   left: 0
  // };

  return (
    <>
        {/* <div style={divStyle} className="py-[150px] bg-no-repeat bg-center bg-cover relative h-[250px] md:h-[400px] shadow-inner">
        <div style={overlayStyle}></div>
        </div> */}
  <div className=" border-b border-gray-400 w-2/3 mx-auto my-[50px]" id='contact'></div>
    <div className='flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-[150px] mt-[50px] mx-3'>
      <img src={process.env.PUBLIC_URL + '/contact.webp'} alt="Description" className='md:w-1/3  mb-10 md:mb-0  mx-3' />
    </div>
    <div className='flex flex-col-reverse md:flex-row-reverse  justify-center md:mt-[50px] mb-[50px] mx-3'>
    <div className='mx-auto text-justify'>
        
        <div className='max-w-[550px] text-center md:text-start text-themeColor'>
          <p className="flex items-center "><FaLocationDot className="mr-2 text-red-500" /><strong>ADRESSE</strong> : Fougères/Châteaubriant</p><br />
          <p className="flex items-center"><FaPhoneAlt className="mr-2 text-red-500" /><strong>TELEPHONE</strong> : 06 95 17 35 29</p><br />
          <p className="flex items-center"><FaEnvelope className="mr-2 text-red-500" /><strong>EMAIL</strong> : kiato.dv@gmail.com</p><br />
        </div>
      </div>
      <div className='text-justify md:mr-[150px] mx-auto text-themeColor'>
        <h2 className='font-bold text-2xl mb-3 text-center md:text-start '>Disponibilité</h2>
        <div className='max-w-[550px]'>
          <p>Développeur Web Full Stack fraîchement diplômé, disponible pour des missions poncutelles ou de longues durées. </p><br/>
          <p>Je peux travailler en tant que prestataire pour les entreprises, les collectivités, les associations ou en tant que sous-traitant.</p><br/>
          <p>N'hésitez pas à me contacter pour tout projet spécifique.</p><br/>
          <p></p>
        </div>
      </div>
    </div>


    </>
  )
}
