import React from 'react'
import { FaCheckCircle } from "react-icons/fa";

export default function Skills() {
  return (
    <>
      <div className=' relative flex flex-col items-center text-themeColor' id='skills'>
        <h2 className='pt-[50px] md:pt-[85px] text-center text-themeColor text-3xl md:text-5xl font-bold '>Mes Compétences</h2>
        <img src={process.env.PUBLIC_URL + '/brush2.png'} className="w-[200px] h-[10px] md:w-[350px] md:h-[15px] object-cover opacity-75 mt-5 z-0" alt="Hero" />
        <div className="grid grid-cols-1 md:grid-cols-2 xl:gap-x-[300px] md:gap-x-[100px] gap-y-9 pt-[50px] md:pt-[100px] mx-3 text-justify">
          <div>
            <h3 className="font-bold text-xl pb-2 flex items-center"><FaCheckCircle className="mr-2 text-red-500" />Conception d'interfaces utilisateur (UI/UX Design) </h3>
            <p className='max-w-[550px] md:min-w-[400px]'>Création de maquettes et prototypes pour une expérience utilisateur intuitive et attrayante.</p>
          </div>

          <div>
          <h3 className="font-bold text-xl pb-2 flex items-center"><FaCheckCircle className="mr-2 text-red-500" />Bases de données </h3>
            <p className='max-w-[550px] md:min-w-[400px]'>Modélisation et gestion de bases de données relationnelles (MySQL, PostgreSQL) et non-relationnelles MongoDB.</p>
          </div>
          <div>
          <h3 className="font-bold text-xl pb-2 flex items-center"><FaCheckCircle className="mr-2 text-red-500" />Développement Front-end </h3>
            <p className='max-w-[550px] md:min-w-[400px]'>Utilisation des technologies web modernes (HTML5, CSS3, JavaScript, React) pour construire des interfaces réactives et performantes.</p>
          </div>
          <div>
          <h3 className="font-bold text-xl pb-2 flex items-center"><FaCheckCircle className="mr-2 text-red-500" />Sécurité Web </h3>
            <p className='max-w-[550px] md:min-w-[400px]'>Application des bonnes pratiques de sécurité (HTTPS, OWASP, authentification...), protection contre les attaques (injection SQL, XSS...).</p>
          </div>
          <div>
          <h3 className="font-bold text-xl pb-2 flex items-center"><FaCheckCircle className="mr-2 text-red-500" />Développement Back-end </h3>
            <p className='max-w-[550px] md:min-w-[400px]'>Utilisation du langages côté serveur Node.js et du framework Express pour la création d'API RESTful et la gestion de bases de données.</p>
          </div>
          <div>
          <h3 className="font-bold text-xl pb-2 flex items-center"><FaCheckCircle className="mr-2 text-red-500" />Intégration / Déploiement Continu (CI/CD) </h3>
            <p className='max-w-[550px] md:min-w-[400px]'>Automatisation des processus de build, test et déploiement pour des livraisons rapides et fiables. Utilisation des outils de gestion de version (GitLab, GitHub) et de suivi de projet.</p>
          </div>
        </div>
        <img src={process.env.PUBLIC_URL + '/planete.webp'} className="absolute w-auto h-[350px] object-cover mt-[300px] md:mt-[250px] z-0 opacity-25 top-0" alt="Hero" />
        <img src={process.env.PUBLIC_URL + '/kiatoweb-logo2.webp'} className="hidden md:block absolute w-auto h-[75px] object-cover mt-[375px] z-0 opacity-15 top-0" alt="Hero" />
        <div className="grid grid-cols-5 md:grid-cols-10 gap-4 pt-[50px] md:pt-[100px] ">
          <img src={process.env.PUBLIC_URL + '/logo/html5.svg'} alt="Logo 1 " className='h-[40px] md:h-[54px]' />
          <img src={process.env.PUBLIC_URL + '/logo/css3.svg'} alt="Logo 2" className='h-[40px] md:h-[54px]' />
          <img src={process.env.PUBLIC_URL + '/logo/sass.svg'} alt="Logo 5" className='h-[40px] md:h-[54px]'/>
          <img src={process.env.PUBLIC_URL + '/logo/js.svg'} alt="Logo 3" className='h-[40px] md:h-[54px]' />
          <img src={process.env.PUBLIC_URL + '/logo/react.svg'} alt="Logo 4" className='h-[40px] md:h-[54px]' />
          <img src={process.env.PUBLIC_URL + '/logo/express.png'} alt="Logo 9" className='h-[40px] md:h-[54px]'/>
          <img src={process.env.PUBLIC_URL + '/logo/wordpress.svg'} alt="Logo 7"  className='h-[40px] md:h-[54px]'/>
          <img src={process.env.PUBLIC_URL + '/logo/github.svg'} alt="Logo 8" className='h-[40px] md:h-[54px]' />
          <img src={process.env.PUBLIC_URL + '/logo/photoshop.svg'} alt="Logo 10" className='h-[40px] md:h-[54px]' />
          <img src={process.env.PUBLIC_URL + '/logo/babel.svg'} alt="Logo 6" className='h-[40px]  md:h-[54px]' />
        </div>
        <h3 className='pb-[50px] pt-[15px] mx-3'>TAILWIND / BOOTSTRAP / STABLE DIFFUSION / PRESTASHOP</h3>
      </div>
    </>
    
  )
}
