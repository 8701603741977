import React from 'react'

export default function Footer() {
  return (
    <>
      <footer>
        <div className='bg-neutral bg-navColor h-[50px] mt-8 shadow-xl flex items-center justify-center text-white'> 
        2024 Kiato-Web - Réalisé avec <img src="/favicon.ico" alt="" className='h-5 ml-2' />
        </div>
      </footer>
    </>
  )
}